<template>
  <v-timeline align-top dense>
    <!-- Task Created info -->
    <v-timeline-item color="undefined">
      <template v-slot:icon>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-avatar v-on="on">
              <v-icon :color="taskCreatedColorClass">{{ iconTask }}</v-icon>
            </v-avatar>
          </template>
          <span>Created On {{ getTaskCreatedDate(task) }}</span>
        </v-tooltip>
      </template>

      <v-row>
        <!-- Task Created Date -->
        <v-col cols="12" md="5">
          <div :class="columnHeaderClass">
            Created
          </div>
          <div :class="columnBodyClass">
            {{ getTaskCreatedDate(task) }}
          </div>
        </v-col>

        <v-col cols="12" md="7">
          <div :class="columnHeaderClass">
            {{ getTaskName(task) }}
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>

    <!-- Task Due -->
    <task-due-timeline-item
      :task="task"
      v-show="visibleTaskDueBeforeQueued"
    ></task-due-timeline-item>

    <!-- Task Status -->
    <v-timeline-item color="undefined">
      <template v-slot:icon>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-avatar v-on="on">
              <v-icon :color="getStatusColorClass(task)">{{ iconTask }}</v-icon>
            </v-avatar>
          </template>
          <span>{{ taskStatusTooltip(task) }}</span>
        </v-tooltip>
      </template>

      <v-row>
        <!-- Task Status Info -->
        <v-col cols="12" md="5">
          <div :class="taskStatusTextClass(task)">
            {{ getTaskStatus(task) }}
          </div>
          <div :class="columnBodyClass" v-if="visibleTaskStatusDate(task)">
            {{ getStatusChangedDate(task) }}
          </div>
        </v-col>

        <v-col cols="12" md="7">
          <!--          <div :class="columnHeaderClass">-->
          <!--            {{ getTaskName(task) }}-->
          <!--          </div>-->
          <div>
            <span :class="columnLabelClass">Assignee: </span>
            <span :class="columnBodyClass">
              {{ getTaskAssignee(task) }}
            </span>
          </div>
          <div>
            <span :class="columnLabelClass">Priority: </span>
            <span :class="priorityTextClass(task)">
              {{ getTaskPriority(task) }}
            </span>
          </div>
          <div v-if="visibleDuration(task)">
            <span :class="columnLabelClass">Duration: </span>
            <span :class="columnBodyClass">
              {{ getTaskDuration(task) }}
            </span>
          </div>
          <div v-if="visibleTaskDue(task)">
            <span :class="columnLabelClass">Due: </span>
            <span :class="dueDateTextClass(task)">
              {{ getTaskDueDate(task) }}
            </span>
          </div>
          <div v-if="visibleTaskOverdue(task)">
            <span :class="overdueTextClass(task)">Overdue!</span>
          </div>
          <div v-if="visibleMilestone(task)">
            <span :class="columnLabelClass">Milestone!</span>
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>

    <!-- curren DateTime -->
    <timeline-item-now
      v-show="visibleCurrentDateBeforeDueDate"
    ></timeline-item-now>

    <!-- Task Due -->
    <task-due-timeline-item
      :task="task"
      v-show="visibleTaskDueAfterQueued"
    ></task-due-timeline-item>

    <!-- curren DateTime -->
    <timeline-item-now
      v-show="visibleCurrentDateAfterDueDate"
    ></timeline-item-now>
  </v-timeline>
</template>

<script>
// utils
import { isEarlierThan } from "@/utils";

// mixins
import { timelineTaskMixin } from "@/mixins/shared/timeline/timelineTaskMixin";
import { toLocalDateTimeString } from "@/filters/dateFilter";

export default {
  name: "TaskTimeline",
  mixins: [timelineTaskMixin],
  components: {
    TimelineItemNow: () =>
      import("@/components/shared/core/timeline/TimelineItemNow"),
    TaskDueTimelineItem: () =>
      import("@/components/task/TaskDueTimelineItem.vue")
  },
  props: {
    task: undefined
  },
  computed: {
    visibleTaskDueBeforeQueued() {
      if (!this.visibleTaskDue(this.task)) return false;
      return isEarlierThan(this.task?.dueDate, this.task?.statusChanged);
    },
    visibleTaskDueAfterQueued() {
      if (!this.visibleTaskDue(this.task)) return false;
      return isEarlierThan(this.task?.statusChanged, this.task?.dueDate);
    },
    visibleCurrentDateBeforeDueDate() {
      if (!this.visibleTaskDue(this.task)) return true;
      return isEarlierThan(this.currenDateTime, this.task?.dueDate);
    },
    visibleCurrentDateAfterDueDate() {
      if (!this.visibleTaskDue(this.task)) return false;
      return isEarlierThan(this.task?.dueDate, this.currenDateTime);
    },
    currenDateTime() {
      return toLocalDateTimeString(new Date().toString());
    }
  }
};
</script>

<style scoped></style>
